/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import { CollectionLayout } from "./section";
import get from "lodash/get";

const CollectionPageBase = props => {
  const collectionName = get(props, ["data", "collection", "name"], "");

  return (
    <>
      {/* H1 is not required visually but need for SEO */}
      <h1 style={{ display: "none" }}>{collectionName}</h1>
      <CollectionLayout {...props} />
    </>
  );
};

function mapStateToProps(state) {
  return {
    config: state.qt.config
  };
}

export const CollectionPage = connect(mapStateToProps)(CollectionPageBase);
