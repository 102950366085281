/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { collectionWithIntroductionCard } from "../../../utils";
import { getCollectionTemplate } from "../../../components/get-collection-template";
import { Collection } from "@quintype/components";
import SearchPageLayout from "./search-layout";
import "../../../../assets/stylesheets/search-page.scss";
import "./search-page.m.css";

const SearchCollectionLayout = ({ config = {}, data = {} }) => {
  const collection = collectionWithIntroductionCard(config, data.stories, "search", data);
  return <Collection config={config} collection={collection} collectionTemplates={getCollectionTemplate} />;
};

SearchCollectionLayout.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
};

const SearchPage = ({ data = {}, config = {}, builderConfig }) => {
  const pbRows = get(config, ["pagebuilder-config", "search", "rows"], []);
  return pbRows.length > 0 ? (
    <SearchCollectionLayout config={config} data={data} />
  ) : (
    <SearchPageLayout builderConfig={builderConfig} data={data} config={config} />
  );
};

SearchPage.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
  builderConfig: PropTypes.object,
};

export { SearchPage, SearchCollectionLayout };

export default SearchPage;
