/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import last from "lodash/last";
import { addAssociatedMetadata, getDefaultStoriesCount } from "./utils";

const getCollectionBySlug = async (slug) => {
  const collectionData = await (await fetch(`/api/v1/collections/${slug}`)).json();
  return collectionData;
};

const getChildCollectionData = async (collection) =>
  Promise.all(
    collection.items
      .filter((collectionItem) => collectionItem.type === "collection")
      .map((collectionItem) => (collectionItem.slug ? getCollectionBySlug(collectionItem.slug) : collectionItem))
  );

const getLastCollectionRow = (rows) => {
  const collectionRows = rows.filter((row) => row.type === "collections");
  return collectionRows.length ? last(collectionRows) : {};
};

const updatedStoriesAndCollectionPageData = async (rows, collection) => {
  const initialCollections = new Array(rows.length).fill({});
  const collectionItems = collection.items.filter((collectionItem) => collectionItem.type === "collection");
  const storyItems = collection.items.filter((collectionItem) => collectionItem.type === "story");
  const updatedStoryItems = [...storyItems];
  const isAdOrWidget = (type) => ["ads", "widget"].includes(type);
  let currentOffset = 0;
  const collectionsCache = {};
  let collectionIndex = 0;
  for (const index in rows) {
    const row = rows[index];
    let { config = {}, layout, rowId: currentRowId, type } = row;
    const { initialLoadCount = "", numberOfStoriesToShow = "" } = config;
    const storiesCount =
      parseInt(initialLoadCount) || parseInt(numberOfStoriesToShow) || getDefaultStoriesCount(layout);

    const getSlotCount = (layout) => {
      switch (layout) {
        case "ArrowThreeColSixStories":
          return 1;
        case "ArrowTwoColSixStories":
        case "ArrowThreeColSevenStory":
          return 2;
        case "ArrowElevenStories":
          return 5;
        default:
          return 0;
      }
    };

    switch (config.dataType) {
      case "collection":
        const childCollectionStories = get(collectionItems, [0, "items"], []).filter((item) => item.type === "story");
        if (collectionItems.length) {
          const currentRowCollectionData = {
            ...collectionItems[0],
            items: childCollectionStories,
            "associated-metadata": row,
          };
          initialCollections[index] = currentRowCollectionData;
        }
        break;
      case "collectionWithSlug":
        if (!collectionsCache[config.slug]) {
          collectionsCache[config.slug] = { collectionInfo: {} };
          // copy collection info
          const collectionInfo = {};
          const collectionSlugData = await getCollectionBySlug(config.slug);
          for (const key in collectionSlugData) {
            if (key !== "items") collectionInfo[key] = collectionSlugData[key];
          }
          const getCollectionItems = await getChildCollectionData(collectionSlugData);
          const getStoryItems = collectionSlugData.items.filter((collectionItem) => collectionItem.type === "story");
          collectionsCache[config.slug] = {
            collectionInfo,
            stories: { items: getStoryItems },
            collections: { items: getCollectionItems },
          };
        }
        if (collectionsCache[config.slug].stories.items.length) {
          const storiesFromCache = collectionsCache[config.slug].stories.items;
          const currentRowCollectionData = {
            ...collectionsCache[config.slug]["collectionInfo"],
            type: "collection",
            items: storiesFromCache,
            "associated-metadata": row,
          };
          initialCollections[index] = { ...currentRowCollectionData, type: "collection", "associated-metadata": row };
        }
        break;
      default:
        const lastCollectionRow = getLastCollectionRow(rows);
        if (lastCollectionRow.rowId !== currentRowId) {
          config = { ...config, showButton: false };
        }
        if (isAdOrWidget(type)) {
          initialCollections[index]= {
            type: "collection",
            "associated-metadata": row,
            items: [],
          }
 
        } else if(updatedStoryItems.length) {
          currentOffset = currentOffset + storiesCount;
          let updateStoryCount = storiesCount;
          const slotConfig = get(config, ["slotConfig", "0", "type"], "story");

          if (getSlotCount(rows[index].layout) && slotConfig !== "story") {
            updateStoryCount = storiesCount - getSlotCount(rows[index].layout);
            currentOffset = currentOffset - getSlotCount(rows[index].layout);
          }

          const itemsForCurrentRow = updatedStoryItems.splice(0, updateStoryCount);
          const currentRowCollectionData = {
            ...collection,
            type: "collection",
            items: itemsForCurrentRow,
            "associated-metadata": row,
            currentOffset,
            config,
          };
          initialCollections[index] = currentRowCollectionData;
        } else if (!storyItems.length && collectionItems[collectionIndex]) {
          const childCollectionStories = get(collectionItems, [collectionIndex, "items"], []).filter(
            (item) => item.type === "story"
          );
          const currentRowCollectionData = {
            ...collectionItems[collectionIndex],
            items: childCollectionStories,
            "associated-metadata": row,
          };
          initialCollections[index] = currentRowCollectionData;
          collectionIndex++;
        }
    }
  }
  return { type: "collection", items: initialCollections };
};

export async function overrideLayoutsForSection(config, collection) {
  const pageConfig = get(config, ["pagebuilder-config", "section"]);
  const layouts = get(config, ["pagebuilder-config", "section", "rows"]);
  if (!layouts) {
    return collection;
  }

  try {
    const layoutDataType = get(pageConfig, ["pageSetting", "layoutDataType"], "storiesAndCollections");
    // If section page is set to collection of collections and there are no collections present, we should treat it as collection of stories
    if (
      layoutDataType === "storiesAndCollections" ||
      (layoutDataType === "collections" &&
        collection.items &&
        !collection.items.find((item) => item.type === "collection"))
    ) {
      return updatedStoriesAndCollectionPageData(layouts, collection);
    }
  } catch (error) {
    return collection;
  }
  // layoutDatatype === "collections" and collections exist
  const itemsWithCollection = collection.items.filter((item) => item.type === "collection");
  collection.items = itemsWithCollection;
  return addAssociatedMetadata(collection, layouts);
}
