/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { LoadMoreStoriesBase, LoadMoreCollectionStories, Collection } from "@quintype/components";
import PropTypes from "prop-types";
import { getCollectionTemplate } from "../get-collection-template";
import { updateStoriesWithAlternatives, overrideLayouts } from "../../utils";
import { overrideLayoutsForSection } from "../../section-page-utils";
import { ThreeColGrid } from "@quintype/arrow";

const SectionPageBase = (props) => {
  const bgColor = get(props, ["config", "pagebuilder-config", "section", "pageSetting", "bgColor"], "");
  const collectionName = get(props, ["data", "collection", "name"], "");
  return (
    <div style={{ backgroundColor: bgColor || "initial" }}>
      {/* H1 is not required visually but need for SEO */}
      <h1 style={{ display: "none" }}>{collectionName}</h1>
      <CollectionLayout {...props} />
    </div>
  );
};

export function CollectionLayout(props) {
  const [customCollection, setCustomCollection] = useState(null);
  const collectionData = get(props, ["data", "collection", "items"], []);

  useEffect(() => {
    // get collection data from slug in pagebuilder config, this request is made from the client side.
    // if there are too many rows with different collection slugs it will take a long time to render on server side.
    const getOverrideLayouts = async () => {
      const setLayout = await overrideLayoutsForSection(props.config, props.data.collection);
      setCustomCollection(setLayout);
    };
    customCollection === null && getOverrideLayouts();
  }, [customCollection, props.config, props.data.collection]);

  const updatedCollectionWithAlternativesData = updateStoriesWithAlternatives(
    collectionData.filter((item) => item.type === "story") // Initially collectionData is null which checks for collection to avoid returning undefined.
  );

  const pageConfig = get(props.config, ["pagebuilder-config", "section"]);
  const layoutDataType = get(pageConfig, ["pageSetting", "layoutDataType"]);
  const collection =
    layoutDataType === undefined ? overrideLayouts(props.config, props.data.collection, "section") : customCollection;
  return (
    collection &&
    (collection.items.some((collection) => collection.type === "collection") ? (
      <Collection config={props.config} collection={collection} collectionTemplates={getCollectionTemplate} />
    ) : (
      <LoadMoreStories
        {...props}
        stories={updatedCollectionWithAlternativesData}
        languageDirection={get(props.config, ["language", "direction"], "ltr") === "ltr" ? "left" : "right"}
      />
    ))
  );
}
CollectionLayout.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object,
};

function LoadMoreStoriesTemplate(props) {
  const defaultTemplate = () => {
    const getStoryData = get(props, ["stories"], []).map((story) => ({ story: story, type: "story" }));
    const collection = { type: "collection", items: getStoryData, name: get(props, ["collection", "name"], "") };
    return (
      <div className="component-wrapper">
        <ThreeColGrid collection={collection} isLoadMoreVisible={false} />
      </div>
    );
  };
  return defaultTemplate();
}

export function LoadMoreStories(props) {
  const collection = get(props, ["data", "section", "collection"], null);
  const storyFields =
    "headline,subheadline,summary,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template,url";
  const data = Object.assign({}, props.data, { stories: get(props, ["stories"], []) });
  const sectionId = get(props, ["data", "section", "id"], -1);
  const numStoriesToLoad = 10;

  return collection ? (
    <LoadMoreCollectionStories
      template={LoadMoreStoriesTemplate}
      collectionSlug={collection.slug}
      params={{ "item-type": "story" }}
      data={data}
      languageDirection={props.languageDirection}
      numStoriesToLoad={numStoriesToLoad}
    />
  ) : (
    <LoadMoreStoriesBase
      template={LoadMoreStoriesTemplate}
      fields={storyFields}
      params={{ "section-id": sectionId }}
      data={data}
      languageDirection={props.languageDirection}
      numStoriesToLoad={numStoriesToLoad}
    />
  );
}
LoadMoreStories.propTypes = {
  data: PropTypes.object,
  languageDirection: PropTypes.string,
  stories: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    config: get(state, ["qt", "config"], {}),
  };
}

export const SectionPage = connect(mapStateToProps)(SectionPageBase);
