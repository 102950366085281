/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect, useState } from 'react';
import { MagazineEditions, CollectionName } from '@quintype/arrow';
import '../issue-landing-page/issue-landing-page.m.css';
import { getPath, getTextContrast } from '../../../utils';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

const getCollectionDateBeforeAfter = (year, month) => {
  // months value starts from 0
  const collectionDateBefore =
    month === 12 ? Date.UTC(year, month, 1) - 6 * 60 * 60 * 1000 : Date.UTC(year, month + 1, 1) - 6 * 60 * 60 * 1000;
  const collectionDateAfter = month === 12 ? Date.UTC(year - 1, month, 1) : Date.UTC(year, month, 1);
  return { collectionDateBefore, collectionDateAfter };
};

export const FilterEditions = ({ row = {}, data = {}, bgColor = '' }) => {
  const { entityId, magazineSlug = '', allEditions = [] } = data;
  const { config = {}, name = '' } = row;
  const {
    subsequentLoadCount = 8,
    initialLoadCount = 12,
    sortBy,
    showButton = true,
    showRowTitle,
    collectionNameTemplate = 'default',
    rowTitle = 'Archive'
  } = config;
  const sketchesHost = useSelector(state => get(state, ['qt', 'config', 'sketches-host']));
  const currentYear = new Date().getFullYear();
  const sortByOrder = sortBy === 'latest-issues' ? 'desc' : 'asc';
  const textColor = getTextContrast(bgColor);
  let yearOptions = [];
  const monthOptions = [
    { label: 'All Months', value: 12 },
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ];

  const [showYearOptions, setShowYearOptions] = useState(false);
  const [showMonthOptions, setShowMonthOptions] = useState(false);
  const [editions, updateEditions] = useState(allEditions);
  const [limit, handleLimit] = useState(initialLoadCount);
  const [offset, handleOffset] = useState(initialLoadCount);
  const showLoadmoreButton = showButton && !allEditions.length < initialLoadCount;
  const [showLoadMore, handleLoadMore] = useState(showLoadmoreButton);
  const [selectedMonth, setMonth] = useState({ label: 'All Months', value: 12 });
  const [selectedYear, setYear] = useState({ label: 'Select Year', value: '' });
  const [firstEditionPublishedDate, setFirstEditionPublishedDate] = useState();

  const toggleSelect = (toggleYear, toggleMonth) => {
    setShowYearOptions(toggleYear);
    setShowMonthOptions(toggleMonth);
  };

  useEffect(() => {
    const containerElement = global && global.document && global.document.getElementById('container');
    containerElement.addEventListener(
      'click',
      event =>
        !(event.target.className.includes('select-overlay') || event.target.className.includes('item')) &&
        toggleSelect(false, false)
    );
  }, []);

  const getFirstEditionPublishedDate = async () => {
    try {
      // the below call is to fetch the first published date of the magazine based on collection-date in ascending order
      const entityUrl = getPath(
        sketchesHost,
        `/api/v1/entity/${entityId}/collections?sort-by=collection-date&order=asc`
      );
      const data = await fetch(entityUrl);
      const { collections = [] } = await data.json();
      let firstPublishedCollectionDate;
      firstPublishedCollectionDate = collections[0] && collections[0]['collection-date'];
      firstPublishedCollectionDate = new Date(firstPublishedCollectionDate).getFullYear() || currentYear;
      setFirstEditionPublishedDate(firstPublishedCollectionDate);
    } catch (err) {
      console.error(`failed to get editions data: ${err}`);
    }
  };

  const updateAllEditions = async (year, month) => {
    const { collectionDateBefore, collectionDateAfter } = getCollectionDateBeforeAfter(year, month);
    try {
      const entityUrl = getPath(
        sketchesHost,
        `/api/v1/entity/${entityId}/collections?limit=${initialLoadCount}&sort-by=collection-date&order=${sortByOrder}&collection-date-before=${collectionDateBefore}&collection-date-after=${collectionDateAfter}`
      );
      const data = await fetch(entityUrl);
      const result = await data.json();
      const newAllEditions =
        result &&
        result.collections.map(item => {
          return {
            collection: item
          };
        });
      updateEditions(newAllEditions);
    } catch (err) {
      console.error(`failed to get editions data: ${err}`);
    }
  };

  const loadMoreIssues = async () => {
    const { collectionDateBefore, collectionDateAfter } = getCollectionDateBeforeAfter(
      selectedYear.value,
      selectedMonth.value
    );
    let data;
    try {
      // adding this condition bcz if we don't use filter then we dont want the other query parameters
      if (!selectedYear.value) {
        const entityUrl = getPath(
          sketchesHost,
          `/api/v1/entity/${entityId}/collections?limit=${subsequentLoadCount}&offset=${offset}&sort-by=collection-date&order=${sortByOrder}`
        );
        data = await fetch(entityUrl);
      } else {
        const entityUrl = getPath(
          sketchesHost,
          `/api/v1/entity/${entityId}/collections?limit=${subsequentLoadCount}&offset=${offset}&sort-by=collection-date&order=${sortByOrder}&collection-date-before=${collectionDateBefore}&collection-date-after=${collectionDateAfter}`
        );
        data = await fetch(entityUrl);
      }

      const result = await data.json();
      const newEditions =
        result &&
        result.collections.map(item => {
          return {
            collection: item
          };
        });
      const otherEditions = [...editions, ...newEditions];
      const updatedOffset = parseInt(subsequentLoadCount, 10) + parseInt(offset, 10);
      handleOffset(updatedOffset);
      (otherEditions === [] || otherEditions.length < updatedOffset) && handleLoadMore(false);
      updateEditions(otherEditions);
      handleLimit(parseInt(limit, 10) + parseInt(subsequentLoadCount, 10));
    } catch (err) {
      console.error(`failed to get other editions data: ${err}`);
    }
  };

  if (!firstEditionPublishedDate) {
    // this function gets the first published date
    getFirstEditionPublishedDate();
  }

  for (let year = currentYear; year >= firstEditionPublishedDate; year--) {
    yearOptions.push({ label: year, value: year });
  }

  const yearMonthSelector = (year, month) => {
    setMonth(month);
    setYear(year);
    updateAllEditions(year.value, month.value);
  };

  const getDropDown = (key, selectedOption, showOptions, toSelectOptions) => {
    const isYear = key === 'year';
    const updatedYear = selectedYear.value ? selectedYear : { label: currentYear, value: currentYear };

    return (
      <div styleName='select-wrapper'>
        <div
          styleName='select-overlay'
          onClick={() => (isYear ? toggleSelect(!showYearOptions, false) : toggleSelect(false, !showMonthOptions))}
        >
          {selectedOption.label}
        </div>
        {showOptions && (
          <div styleName='select-items'>
            {toSelectOptions.map((option, index) => (
              <div
                styleName='item'
                key={`${option.label}-${index}`}
                onClick={() => {
                  isYear ? yearMonthSelector(option, selectedMonth) : yearMonthSelector(updatedYear, option);
                  toggleSelect(false, false);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div styleName='editions-wrapper'>
      <div
        className='arrow-component full-width-with-padding'
        styleName={`filter-wrapper ${textColor}`}
        style={{ background: bgColor || 'initial', justifyContent: !showRowTitle ? 'flex-end' : 'initial' }}
      >
        <div styleName='name-filter-wrapper'>
          {showRowTitle && (
            <CollectionName
              collectionNameTemplate={collectionNameTemplate}
              customCollectionName={rowTitle}
              navigate={false}
              config={config}
            />
          )}
          <div styleName='filter'>
            {getDropDown('year', selectedYear, showYearOptions, yearOptions)}
            {getDropDown('month', selectedMonth, showMonthOptions, monthOptions)}
          </div>
        </div>
        {!editions.length && <div styleName='empty-result'>No Results Found</div>}
      </div>
      <MagazineEditions
        key={name}
        collection={editions}
        config={{
          ...config,
          magazineSlug,
          'sketches-host': sketchesHost,
          template: 'SubsequentLoadCount',
          showRowTitle: false,
          theme: bgColor
        }}
        onClick={loadMoreIssues}
        showLoadmore={showLoadMore}
        limit={offset}
      />
    </div>
  );
};

FilterEditions.propTypes = {
  data: PropTypes.shape({
    magazineSlug: PropTypes.string,
    allEditions: PropTypes.array
  }),
  bgColor: PropTypes.string,
  row: PropTypes.shape({
    name: PropTypes.string,
    config: PropTypes.shape({
      subsequentLoadCount: PropTypes.number,
      initialLoadCount: PropTypes.number,
      sortBy: PropTypes.string,
      showButton: PropTypes.bool,
      showRowTitle: PropTypes.bool,
      theme: PropTypes.string,
      collectionNameTemplate: PropTypes.string,
      rowTitle: PropTypes.string
    })
  })
};
