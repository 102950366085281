/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { Collection } from "@quintype/components";
import get from "lodash/get";
import { isEmpty, updateStoriesWithAlternatives, collectionWithIntroductionCard } from "../../../utils";
import PropTypes from "prop-types";
import { getCollectionTemplate } from "../../get-collection-template";
import { AuthorIntroductionCard, ThreeColGrid } from "@quintype/arrow";
import "./author-page.module.css";

const AuthorPage = (props) => {
  const updatedStoriesWithAlternatives = updateStoriesWithAlternatives(props.data.stories);
  const authorDetails = get(props, ["data", "author"], {});
  const collection = collectionWithIntroductionCard(
    props.config,
    updatedStoriesWithAlternatives,
    "author",
    authorDetails
  );
  const pbLayout = get(props.config, ["pagebuilder-config", "author", "rows"], []);

  const AuthorPageCollectionLayout = () => {
    return <Collection id="one-col-list" config={props.config} collection={collection} collectionTemplates={getCollectionTemplate} />;
  };

  const AuthorPageStoryLayout = ({ props }) => {
    const stories = get(props, ["data", "stories"], []);
    const getStoryData = stories.map((item) => ({ story: item.story, type: "story" }));
    const collection = { type: "collection", items: getStoryData, name: get(props, ["collection", "name"], "") };
    return (
      <div className="component-wrapper">
        {!isEmpty(props.data) ? (
          <>
            <AuthorIntroductionCard data={props.data.author} />
            {stories.length ? (
              <ThreeColGrid collection={collection} isLoadMoreVisible={false} />
            ) : (
              <h1>No stories found for this author</h1>
            )}
          </>
        ) : (
          <h1>Author not found</h1>
        )}
      </div>
    );
  };

  return <>{pbLayout.length > 0 ? <AuthorPageCollectionLayout /> : <AuthorPageStoryLayout props={props} />}</>;
};

AuthorPage.propTypes = {
  data: PropTypes.object,
  props: PropTypes.object,
  config: PropTypes.object,
};

export { AuthorPage };
