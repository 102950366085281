/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { LoadMoreStoriesBase, Collection } from "@quintype/components";
import PropTypes from "prop-types";
import { collectionWithIntroductionCard } from "../../utils";
import { getCollectionTemplate } from "../get-collection-template.js";
import { OneColStoryList } from "@quintype/arrow";
class TagPageBase extends React.Component {
  render() {
    const stories = get(this.props, ["data", "stories"], []);
    const collection = collectionWithIntroductionCard(this.props.config, stories, "tag", this.props.data);
    const pbLayout = get(this.props.config, ["pagebuilder-config", "tag", "rows"], []);
    if (stories.length < 1) {
      return (
        <div className="component-wrapper">
          <h1 style={{ color: this.props.enableDarkMode ? "#fff" : "#333" }}>
            No stories found for tag &#39; <strong>{this.props.data.tagName}</strong>&#39; .
          </h1>
        </div>
      );
    }
    const TagPagCollectionLayout = () => {
      return (
        <Collection config={this.props.config} collection={collection} collectionTemplates={getCollectionTemplate} />
      );
    };
    if (pbLayout.length > 0) return <TagPagCollectionLayout />;
    return <LoadMoreStories {...this.props} stories={stories} />;
  }
}

TagPageBase.propTypes = {
  data: PropTypes.object,
  stories: PropTypes.array,
  config: PropTypes.object,
  enableDarkMode: PropTypes.bool,
};

function LoadMoreStories(props) {
  const storyFields =
    "headline,subheadline,summary,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template,url";
  const data = Object.assign({}, props.data, { stories: props.stories });
  const tagSlugs = get(props, ["data", "tagSlug"]);
  const numStoriesToLoad = 20;

  return (
    <LoadMoreStoriesBase
      template={LoadMoreStoriesTemplate}
      fields={storyFields}
      params={{ "tag-slugs": tagSlugs }}
      data={data}
      numStoriesToLoad={numStoriesToLoad}
    />
  );
}
LoadMoreStories.propTypes = {
  data: PropTypes.object,
  stories: PropTypes.array,
};
function LoadMoreStoriesTemplate(props) {
  const getStoryData = get(props, ["stories"], []).map((story) => ({ story: story, type: "story" }));

  const collection = { type: "collection", items: getStoryData, name: `Results for ${props.tagName}` };
  return (
    <div className="component-wrapper" style={{ minHeight: "100vh" }}>
      {props.stories.length ? (
        <OneColStoryList collection={collection} isLoadMoreVisible={false} />
      ) : (
        <p>
          No stories found for tag &#39; <strong>{props.tagName}</strong>&#39; .
        </p>
      )}
    </div>
  );
}
LoadMoreStoriesTemplate.propTypes = {
  tagName: PropTypes.string,
  stories: PropTypes.array,
  onLoadMore: PropTypes.func,
  noMoreStories: PropTypes.bool,
};

const mapStatetoProps = (state) => {
  return {
    enableDarkMode: get(state, ["header", "isDarkModeEnabled"], false),
  };
};

export const TagPage = connect(mapStatetoProps)(TagPageBase);
