/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { CustomLink } from "../custom-link";
import { Button } from "../basic/button";
import get from "lodash/get";

import "./not-found.module.css";
import { useSelector } from "react-redux";

export const NotFoundPage = () => {
  const { enableLocalization = false, localizedElements = {} } = useSelector((state) =>
    get(state, ["qt", "config", "pagebuilder-config", "general", "localization"], {})
  );
  const { "404Page": errorPage = {} } = enableLocalization ? localizedElements : {};
  const { title: localizedTitle, description: localizedDescription, button: localizedButton } = errorPage;

  return (
    <div styleName="not-found-page">
      <h1 styleName="status-code" data-testid="404">
        404
      </h1>
      <p styleName="info" data-testid="page-not-found">
        {localizedTitle || "Oops.. Page not found !"}
      </p>
      <p styleName="description">
        {localizedDescription ||
          "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable."}
      </p>
      <CustomLink href="/">
        <Button
          classNamesString="qt-button--transparent qt-theme__color qt-theme__color--border"
          data-testid="redirect-homepage-button"
        >
          {localizedButton || "Take me to home"}
        </Button>
      </CustomLink>
    </div>
  );
};
