/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Collection } from "@quintype/components";
import { PageIntroductionCard } from "@quintype/arrow";
import { collectionWithIntroductionCard } from "../../../../isomorphic/utils";
import { getCollectionTemplate } from "../../../../isomorphic/components/get-collection-template";
import { AuthorsListComponent } from "../authors-list";

export const AuthorsPage = (props) => {
  const isPbEnabled = get(props, ["config", "pagebuilder-config", "authors"]);
  if (isPbEnabled) {
    const collection = collectionWithIntroductionCard(props.config, "", "authors", "");
    return <Collection config={props.config} collection={collection} collectionTemplates={getCollectionTemplate} />;
  }
  return (
    <>
      <PageIntroductionCard config={{ pageTitle: "Our Authors", theme: "#fff" }} />
      <AuthorsListComponent />
    </>
  );
};

AuthorsPage.propTypes = {
  config: PropTypes.object,
};
